



















































































































































































import { Component, Vue } from 'vue-property-decorator';

import EditRetirementFundGoalSecondContributionViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-retirement-fund-goal-plan/edit-retirement-fund-goal-second-contribution-view-model';

@Component({
  name: 'EditRetirementFundGoalSecondContribution',
  components: {},
})
export default class EditRetirementFundGoalSecondContribution extends Vue {
  view_model = Vue.observable(
    new EditRetirementFundGoalSecondContributionViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
