import TYPES from '@/types';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';
import { v4 } from 'uuid';

// Application
import { MonthlyCollectionDaysService } from '@/modules/my-investment/allianz/recurring-contributions/application/services';
import EditRetirementFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-plan-service';
import EditRetirementFundGoalPlanDefineByService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-define-by-service';

// Domain
import {
  RecurringContributionDistributionEntity,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/entities/recurring-contribution-distribution-entity';
import {
  MonthlyCollectionDayOptionEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/monthly-collection-day-option-entity';
import {
  InvestorGoalContributionDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/update-plan-custom-goal-dto';
import { StateManager }
  from '@/modules/my-investment/allianz/recurring-contributions/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

type Distribution = {
  collection_day: number;
  amount_field: string;
  total_amount: number;
  new_amount: number;
  total_amount_filed: string;
  show_minimum_amount_error: boolean;
}

export default class EditRetirementFundGoalSecondContributionViewModel {
  @Inject(TYPES.MONTHLY_COLLECTION_DAYS_SERVICE)
  readonly monthly_collection_days_service!: MonthlyCollectionDaysService;

  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  readonly recurring_contributions_state_manager!: StateManager;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditRetirementFundGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_DEFINE_BY_SERVICE)
  private readonly define_by_service!: EditRetirementFundGoalPlanDefineByService;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-configure-second-contribution-wealth';

  readonly view!: Vue;

  recurring_contributions_state = this.recurring_contributions_state_manager.state;

  second_reminder_day = '';

  minimum_amount = 500;

  step = 50;

  limit_per_contribution = 50000;

  invalid_distribution = false;

  monthly_collection_days: Array<MonthlyCollectionDayOptionEntity> = [];

  second_monthly_collection_days: Array<MonthlyCollectionDayOptionEntity> = [];

  distributions: Array<Distribution> = [];

  show_define_collection_day = false;

  disabled_first_day = false;

  total_goal_contribution = 0;

  first_collection_day = 0;

  second_collection_day = 0;

  investor_goal_id = '';

  public constructor(view: Vue) {
    this.view = view;
  }

  get currency_minimum_amount() {
    return `${currencyFormat(this.minimum_amount)} MXN`;
  }

  get currency_total_goal_contribution() {
    return `${currencyFormat(this.total_goal_contribution)} MXN`;
  }

  get is_disabled() {
    return this.first_collection_day === 0
      || this.second_collection_day === 0
      || (this.invalid_distribution);
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  getDefineByInformation = () => (this.define_by_service.getDefineByInformation());

  getCurrentPlanInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  loadMonthlyCollectionDays = () => {
    this.monthly_collection_days = this.monthly_collection_days_service.load();
  }

  loadAvailableDaysOnSecondCollectionDay = () => {
    const days = this.monthly_collection_days_service.load();
    this.second_monthly_collection_days = days.filter(
      (day) => this.monthly_collection_days_service
        .showDay(day, this.first_collection_day),
    );
  }

  collectionDayChange = () => {
    this.loadAvailableDaysOnSecondCollectionDay();
  }

  appendDistribution = () => {
    this.distributions.push({
      collection_day: 0,
      total_amount: 0,
      amount_field: '0',
      new_amount: 0,
      total_amount_filed: `${currencyFormat(0)} MXN`,
      show_minimum_amount_error: false,
    });
  }

  assignBalanceNoContributionsLimitExceeded = (desired_amount: number) => {
    const second_amount = desired_amount - this.limit_per_contribution;
    this.distributions[0].amount_field = this.limit_per_contribution.toString();
    this.changeAssignedAmountField(0);
    this.distributions[1].amount_field = second_amount.toString();
    this.changeAssignedAmountField(1);
  }

  assignBalanceOneContributionLimitExceeded = (desired_amount: number) => {
    this.distributions[1].amount_field = desired_amount.toString();
    this.changeAssignedAmountField(1);
  }

  assignBalanceFirstContributionOnLimit = (desired_amount: number) => {
    if (this.recurring_contributions_state.item.contributions[0]
      .amount === this.limit_per_contribution) {
      this.distributions[1].amount_field = desired_amount.toString();
      this.changeAssignedAmountField(1);
    }
    if (this.recurring_contributions_state.item.contributions[1]
      .amount === this.limit_per_contribution) {
      this.distributions[0].amount_field = desired_amount.toString();
      this.changeAssignedAmountField(0);
    }
  }

  subtractAssigned = (
    distributions: Array<RecurringContributionDistributionEntity>,
    amount: number,
  ) => {
    const { retirement_fund_id } = this.getCurrentPlanInformation();
    const distribution_founded = distributions.find(
      (distribution) => distribution.investor_goal_id === retirement_fund_id,
    );
    if (distribution_founded) {
      return amount - distribution_founded.recurrent_assigned_amount;
    }
    return amount;
  }

  loadMaximumAssignableAmount = (total_contributions: number, monthly_required_amount: number) => {
    if (total_contributions + monthly_required_amount > 100000) {
      this.total_goal_contribution = (total_contributions + monthly_required_amount) - 100000;
    }

    if (total_contributions + monthly_required_amount < 500) {
      this.total_goal_contribution = 500;
    }
  }

  loadDistributions = () => {
    this.appendDistribution();
    this.appendDistribution();
    let total_contributions = 0;
    this.show_define_collection_day = this.recurring_contributions_state.item.contributions
      .length < 2;
    if (this.recurring_contributions_state.item.contributions.length > 0) {
      this.invalid_distribution = false;
      this.disabled_first_day = true;
      this.first_collection_day = this.recurring_contributions_state.item.contributions[0]
        .collection_day;
      this.recurring_contributions_state.item.contributions.forEach((contribution, index) => {
        const contribution_amount = this.subtractAssigned(
          contribution.distributions, contribution.amount,
        );
        total_contributions += contribution_amount;
        this.distributions[index].total_amount = contribution_amount;
        this.distributions[index].total_amount_filed = currencyFormat(contribution_amount);
        this.distributions[index].collection_day = contribution.collection_day;
      });
      this.loadMaximumAssignableAmount(total_contributions, this.total_goal_contribution);
      if (this.recurring_contributions_state.item.contributions.length === 1) {
        this.assignBalanceOneContributionLimitExceeded(this.total_goal_contribution);
      }
      if (this.recurring_contributions_state.item.contributions.length === 2) {
        this.second_collection_day = this.recurring_contributions_state.item.contributions[1]
          .collection_day;
        this.assignBalanceFirstContributionOnLimit(this.total_goal_contribution);
      }
      this.loadAvailableDaysOnSecondCollectionDay();
    } else if (this.total_goal_contribution > this.limit_per_contribution) {
      this.assignBalanceNoContributionsLimitExceeded(this.total_goal_contribution);
    }
  }

  validateTotalAmounts = () => {
    this.invalid_distribution = false;
    let total_amount = 0;
    this.distributions.forEach((distribution) => {
      total_amount += parseCurrencyToNumber(distribution.amount_field);
      if (parseCurrencyToNumber(distribution.total_amount_filed) > 0
        && parseCurrencyToNumber(distribution.total_amount_filed) < this.minimum_amount) {
        this.invalid_distribution = true;
      }
    });
    if (!this.invalid_distribution) {
      this.invalid_distribution = total_amount !== this.total_goal_contribution
        && !this.show_define_collection_day;
    }
  }

  updateOtherAmount = (index: number) => {
    const pending_amount = this.total_goal_contribution
      - parseCurrencyToNumber(this.distributions[index].amount_field);
    let other_index = -1;
    if (index === 0) {
      other_index = 1;
    } else {
      other_index = 0;
    }
    if (this.distributions[other_index].total_amount < this.limit_per_contribution) {
      this.distributions[other_index].amount_field = pending_amount.toString();
      this.distributions[other_index].total_amount_filed = currencyFormat(
        (this.distributions[other_index].total_amount + pending_amount),
      );
    }
  }

  decrementAmount = (index: number) => {
    const current_amount = parseCurrencyToNumber(this.distributions[index].amount_field);
    const amount = current_amount - this.step;
    const new_amount = this.distributions[index].total_amount + amount;
    if (amount >= 0) {
      this.distributions[index].amount_field = amount.toString();
      this.distributions[index].new_amount = new_amount;
      this.distributions[index].total_amount_filed = currencyFormat(
        (this.distributions[index].total_amount + amount),
      );
      this.distributions[index].show_minimum_amount_error = amount > 0
        && new_amount < this.minimum_amount;
      this.updateOtherAmount(index);
      this.validateTotalAmounts();
    }
  }

  incrementAmount = (index: number) => {
    const current_amount = parseCurrencyToNumber(this.distributions[index].amount_field);
    const amount = current_amount + this.step;
    const new_amount = this.distributions[index].total_amount + amount;
    if (amount > 0 && new_amount <= this.limit_per_contribution
      && amount <= this.total_goal_contribution) {
      this.distributions[index].amount_field = amount.toString();
      this.distributions[index].new_amount = new_amount;
      this.distributions[index].total_amount_filed = currencyFormat(
        (this.distributions[index].total_amount + amount),
      );
      this.distributions[index].show_minimum_amount_error = amount > 0
      && new_amount < this.minimum_amount;
      this.updateOtherAmount(index);
      this.validateTotalAmounts();
    }
  }

  changeAssignedAmountField = (index: number) => {
    const current_amount = this.distributions[index].total_amount;
    const amount = parseCurrencyToNumber(this.distributions[index].amount_field);
    const new_amount = current_amount + amount;
    if (new_amount >= 0 && new_amount > this.limit_per_contribution) {
      this.distributions[index].amount_field = '0';
      this.distributions[index].total_amount_filed = currencyFormat(
        (this.distributions[index].total_amount),
      );
    } else {
      this.distributions[index].new_amount = new_amount;
      this.distributions[index].total_amount_filed = currencyFormat(
        (this.distributions[index].total_amount + amount),
      );
      this.distributions[index].show_minimum_amount_error = (
        this.distributions[index].total_amount + new_amount
      ) < this.minimum_amount && amount > 0;
    }
    this.updateOtherAmount(index);
    this.validateTotalAmounts();
  }

  goToPrevStep = () => {
    if (this.recurring_contributions_state.item.contributions.length < 2
      && !this.show_define_collection_day) {
      this.show_define_collection_day = true;
    } else {
      this.view.$emit('prevStep');
    }
  }

  updateContributions = () => {
    const contributions: Array<InvestorGoalContributionDto> = [];
    this.distributions.forEach((distribution, index) => {
      if (index < this.recurring_contributions_state.item.contributions.length) {
        contributions.push({
          id: this.recurring_contributions_state.item.contributions[index].id,
          collection_day: this.distributions[index].collection_day,
          amount: parseCurrencyToNumber(this.distributions[index].amount_field),
        });
      } else {
        contributions.push({
          id: v4(),
          collection_day: this.distributions[index].collection_day,
          amount: parseCurrencyToNumber(this.distributions[index].amount_field),
        });
      }
    });
    return contributions;
  }

  goToNextStep = () => {
    if (this.show_define_collection_day) {
      this.show_define_collection_day = false;
      this.distributions[0].collection_day = this.first_collection_day;
      this.distributions[1].collection_day = this.second_collection_day;
      this.validateTotalAmounts();
    } else {
      const contributions = this.updateContributions();
      this.view.$emit('updatePlan', contributions);
    }
  }

  initialize = () => {
    this.loadMonthlyCollectionDays();
    const define_by = this.getDefineByInformation();
    this.total_goal_contribution = (define_by.is_defined_by_contribution) ? define_by
      .by_contribution.contribution : define_by.by_pension.you_must_save;
    this.loadDistributions();
    this.validateTotalAmounts();
    this.second_reminder_day = this.translate('recurring_not_defined');
  }
}
